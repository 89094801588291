import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import {
  BREEDER_CENTER_PATH,
  BREEDER_DASHBOARD_PATH,
  BREEDER_EDIT_PROFILE_PATH,
  BREEDER_SETTINGS_ACCOUNT_PATH,
  BREEDER_DOWNLOADS_PATH,
  BREEDER_REFERRAL_LINKS_PATH,
  HELP_CENTER_BREEDERS_PATH,
  ROOT_HOST
} from 'dpl/shared/constants/urls';
import { FancyDropdownMenuItem } from 'dpl/components/FancyDropdown';
import NotificationCount from 'dpl/common/components/NotificationCount';
import SmartLink from 'dpl/components/SmartLink';
import Icon from 'dpl/common/components/Icon';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';

export default function UserHeaderLoggedInBreederMenu({
  totalNotificationsCount,
  profilePath,
  isPublished
}) {
  const { pathname } = window.location;
  const currentUser = useCurrentUser();

  const MENU_LINKS = [
    {
      label: 'Dashboard',
      iconName: 'fetch-dashboard',
      path: BREEDER_DASHBOARD_PATH,
      notificationCount: totalNotificationsCount
    },
    {
      label: 'Edit profile',
      iconName: 'fetch-edit-alt',
      path: BREEDER_EDIT_PROFILE_PATH
    },
    {
      label: 'View profile',
      iconName: 'fetch-view',
      path: profilePath
    },
    currentUser.value.data.published && {
      label: 'Referral links',
      iconName: 'fetch-link',
      path: BREEDER_REFERRAL_LINKS_PATH
    },
    {
      label: 'Settings',
      iconName: 'fetch-settings',
      path: BREEDER_SETTINGS_ACCOUNT_PATH
    },
    isPublished && {
      label: 'Downloads',
      iconName: 'fetch-download',
      path: BREEDER_DOWNLOADS_PATH
    },
    {
      label: 'Good Breeder Center',
      iconName: 'fetch-gbc',
      path: BREEDER_CENTER_PATH
    },
    {
      label: 'Help',
      iconName: 'fetch-help',
      path: HELP_CENTER_BREEDERS_PATH
    }
  ].filter(Boolean);

  return (
    <>
      {MENU_LINKS.map(
        ({ label, iconName, path, onClick, notificationCount }) => (
          <FancyDropdownMenuItem key={label} itemKey={label}>
            <SmartLink
              to={path ? `${ROOT_HOST}${path}` : null}
              onClick={onClick}
              className={classnames(
                'flex items-center w-100 pv2 ph4 nowrap font-16 hover-midnight transition',
                pathname.startsWith(path) ? 'midnight' : 'tertiary'
              )}
            >
              <Icon
                className="mr2"
                name={iconName}
                height="24px"
                width="24px"
              />
              <span className="flex-auto">{label}</span>
              {Boolean(notificationCount) && (
                <NotificationCount
                  className="ml2"
                  count={notificationCount}
                  unreadColor="midnight"
                />
              )}
            </SmartLink>
          </FancyDropdownMenuItem>
        )
      )}
    </>
  );
}

UserHeaderLoggedInBreederMenu.propTypes = {
  profilePath: PropTypes.string.isRequired,
  isPublished: PropTypes.bool.isRequired,
  totalNotificationsCount: PropTypes.number.isRequired
};
